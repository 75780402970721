
import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from '@/components/Pagination/index.vue'
import * as directionaryApi from '@/api/dictionary'
import timeFormat from '@/utils/timeFormat'

@Component({
  components: {
    Pagination
  }
})
export default class dictionaryIndexView extends Vue {
  public search: any = {
    pageNumber: 1,
    pageSize: 10,
    total: 0
  }
  public list: Array<any> = []
  deleteRow(row: any) {
    this.$confirm('确实删除该条数据').then((_) => {
      directionaryApi
        .deleteDictionary(row)
        .then((data) => {
          let response = data.data
          if (response.code == 1000) {
            this.search.pageNumber = 1
            this.search.pageSize = 10
            this.$message({
              type: 'success',
              message: '操作成功'
            })
            this.doSearch()
          } else {
            this.$message({
              type: 'error',
              message: response.message
            })
          }
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err
          })
        })
    })
  }
  skipToAddPage(isUpdate: Boolean, params: any) {
    const newObject: any = { ...params }
    newObject.isUpdate = isUpdate ? '1' : '0'
    newObject.saveModel = params
    this.$router.push({ name: 'DictionaryAdd', params: newObject })
  }
  beforeRouteEnter(to: any, from: any, next: any) {
    next((vim: any) => {
      let pageParams: any = sessionStorage.getItem('pageParams')
      if (from.name == 'DictionaryAdd' && pageParams) {
        pageParams = JSON.parse(pageParams)
        vim.search = pageParams.search
      }
      vim.doSearch()
    })
  }
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name == 'DictionaryAdd') {
      let pageParams: any = {
        search: this.search
      }
      sessionStorage.setItem('pageParams', JSON.stringify(pageParams))
    } else if (sessionStorage.getItem('pageParams')) {
      sessionStorage.removeItem('pageParams')
    }
    next()
  }
  private resetFn() {
    Object.keys(this.search).forEach((key) => {
      if (key != 'pageNumber' && key != 'pageSize' && key != 'total') {
        this.search[key] = ''
      }
    })
  }
  doSearch() {
    let params: any = {
      ...this.search
    }
    delete params.total
    directionaryApi
      .pageDictionary(params)
      .then((data) => {
        const responseData = data.data
        if (responseData.code != 1000) {
          this.$message({
            message: data.message,
            type: 'error'
          })
        }
        this.list = responseData.data
        this.search.total = responseData.total
      })
      .catch((err) => {})
  }
}
